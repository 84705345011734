import React, { useState, useEffect, useContext } from 'react';
import {
    BrowserRouter as Router, Route, Redirect, Switch, useLocation, useHistory
} from 'react-router-dom';

import SignIn from '../SignIn';
import SignOut from '../SignOut';
import Profile from '../Profile';
import ProductPreview from '../ProductPreview';
import NotFound from '../NotFound';
import Welcome from '../Welcome';
import Bonus from '../Bonus/Bonus';
import { setPreviewId } from '../../utils';
import { PrivateRoute } from 'stockpoint-common';
import * as path from './path'
import { BonusExchangeUserInfo } from '../Bonus/BonusExchangeUserInfo';
import BonusProgram from '../Bonus/BonusProgram';



export const Routing = () => {
    return (<Switch>
        <Route exact path={path.BASE} component={Welcome} />
        <Route path={path.SIGN_IN} component={SignIn} />
        <Route
            path={path.SIGN_UP}
            render={(props) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <SignIn {...props} signUpMode />
            )}
        />
        <PrivateRoute path={path.SIGN_OUT} component={SignOut} />
        <PrivateRoute path={path.PROFILE} component={Profile} />
        <PrivateRoute path={path.BONUS_PROGRAM} component={BonusProgram} />
        <PrivateRoute path={path.BONUS_EXCHANGE} component={BonusExchangeUserInfo} />
        <PrivateRoute path={path.BONUS} component={Bonus} />
        <PrivateRoute
            exact
            path={path.PREVIEW + "/:id"}
            component={ProductPreview}
            onResolve={(params) => {
                const { id } = params;
                setPreviewId(id);
            }}
        />
        <Redirect from="/:id" to={path.PREVIEW + "/:id"} />
        <Route path="*" component={NotFound} />
    </Switch>)
}
