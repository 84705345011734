import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';

const ConfirmationDialog = (props) => {

  const handleClose = (confirm) => {
    props.setOpen(false);
    props.onSuccessResult(confirm);
  };

  const useStyles = makeStyles(() => ({
    cntr: {
      display: 'flex',
      allignItems: 'center',
      justifyContent: 'center'
    }
  }));

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.cntr} id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          {props.children}
        </DialogContent>
        <DialogActions className={classes.cntr}
          style={{
            fontWeight: '700'
          }}>
          <Button onClick={() => handleClose(false)}>{props.noText}</Button>
          <Button onClick={() => handleClose(true)} autoFocus>{props.yesText}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ConfirmationDialog.defaultProps = {
  title: 'Please confrim',
  yesText: 'Yes',
  noText: 'No'
};


export default ConfirmationDialog