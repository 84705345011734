import React, {
  Fragment, useContext, useState, useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Dialog, DialogContent, DialogContentText, IconButton, InputAdornment, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import makeStyles from '@mui/styles/makeStyles';
import {
  ErrorContext,
  MillaButton, MillaLogo, Toast,
} from 'stockpoint-common';
import { LoadingContext } from '../contexts/LoadingContext';
import client from '../api';
import { BonusContext } from '../contexts/BonusContext';
import { PaperContainer } from './customs/PaperContainer';
import ConfirmationDialog from './customs/ConfirmationDialog';
import ShoppingBagIcon from './icons/ShoppingBagIcon';
import ReorderIcon from '@mui/icons-material/Reorder';
import ProductsScanned from './products_scanned/ProductsScanned';
import Footer from './Footer';

const ProductPreview = () => {
  const { setIsLoading } = useContext(LoadingContext);
  const { setError } = useContext(ErrorContext);
  const { setBonusNeedsReload } = useContext(BonusContext);

  const { id } = useParams();

  const [imageUrl, setImageUrl] = useState(null);
  const [status, setStatus] = useState(null);
  const [showId, setShowId] = useState(false);

  const [data, setData] = useState(null);
  const [submitData, setSubmitData] = useState(null);
  const [copied, setCopied] = useState(null);

  //confirmation
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [productScannedListOpen, setProductScannedListOpen] = useState(false);
  const title = "Confirm product status changes";
  //const [confirmResult, setConfirmResult] = useState(false);


  useEffect(async () => {
    setIsLoading(true);
    try {
      const resp = await client.get(`/product/${id}`);
      setData(resp.data.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      setImageUrl(data.image_url);
      setStatus(data.status);
    }
  }, [data]);

  // eslint-disable-next-line no-shadow



  const handleSubmit = async (status) => {
    setIsLoading(true);
    setError(null);
    setSubmitData(null);
    try {
      const resp = await client.post(`/product/${id}`, { status });
      setSubmitData(resp.data.data);
      setStatus(status); // To update UI
      setBonusNeedsReload(true); // reload bonus info at the app bar
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
  }, [submitData]);

  const useStyles = makeStyles(() => ({
    previewImg: {
      maxWidth: '100%',
      width: '250px',
      borderRadius: '7px',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    paperFooterContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      margin: '40px 20px',
    },
    paperContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '30px 30px 50px 30px',
      width: '100%',
      maxWidth: '500px',
      // background: 'brown',
    },
  }));

  const handleResultFromDialog = async (result) => {
    if (result) {
      await handleSubmit('instore')
    }
  }

  const openDialog = () => {
    setOpenConfirmDialog(true);
  }


  const changeStatusButton = (currStatus) => {
    switch (currStatus) {
      case 'pending':
        return (
          <MillaButton onClick={() => handleSubmit('instore')}>
            Mark as In-Store
          </MillaButton>
        );
      case 'instore':
        return (
          <MillaButton onClick={() => handleSubmit('sold')}>
            Mark as Sold
          </MillaButton>
        );
      case 'sold':
        return (<>
          <Box
            sx={{
              padding: '8px 36px',
              fontSize: "16px"
            }}
            style={{
              display: 'flex',
              allignItems: 'center',
              justifyContent: 'center'
            }}
          > <ShoppingBagIcon></ShoppingBagIcon>
            <span>SOLD</span>
          </Box>
          <MillaButton onClick={() => openDialog()}>
            Return in stock
          </MillaButton>
          <ConfirmationDialog
            onSuccessResult={handleResultFromDialog}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            title={title}>
            <DialogContentText id="alert-dialog-description">
              Are you sure to change the status of the dress ?
            </DialogContentText>
          </ConfirmationDialog>
        </>
        );
      default:
        return (<></>);
    }
  };

  const classes = useStyles();
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={productScannedListOpen}
        onClose={() => setProductScannedListOpen(false)}
      >
        <DialogContent style={{ padding: "16px 8px" }}>
          <ProductsScanned></ProductsScanned>
        </DialogContent>
      </Dialog>

      <Button startIcon={<ReorderIcon />}
        variant="contained"
        style={{ display: "flex", alignSelf: "center", marginTop: "12px", marginBottom: "-48px" }}
        sx={{ textTransform: 'none' }}
        onClick={() => setProductScannedListOpen(true)}>
        Scan history
      </Button>
      <Box className={classes.contentContainer}>
        <Box className={classes.paperFooterContainer}>
          <Box className={classes.paperContainer}>
            <Paper className={classes.paper} elevation={0}>
              <MillaLogo emsize={"2em"} />
              <Stack
                alignItems="center"
                spacing={2}
                sx={{ margin: '20px 0 0 0' }}
              >
                <img
                  className={classes.previewImg}
                  src={imageUrl}
                  alt=""
                />
                {data && !showId && (
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => setShowId(true)}
                    style={{ backgroundColor: "rgb(248 248 248)", borderRadius: "10px", margin: '8px 0 0 0' }}
                    sx={{ textTransform: 'none' }}
                  >

                    Show ID
                  </Button>
                )}
                {data && showId && (
                  <TextField
                    label="ID"
                    sx={{ m: 1 }}
                    value={id}
                    InputProps={{
                      readOnly: true,
                      endAdornment: <InputAdornment position="end">
                        <Tooltip title="Copy to clipboard">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={async () => {
                              await navigator.clipboard.writeText(id).then(() => { setCopied(true); setShowId(false) });
                            }}
                          >
                            <ContentCopyIcon></ContentCopyIcon>
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    }}
                  />
                )}

                <Typography variant="h6" align="center">
                  {data && [data.model, data.color, data.size].join(', ')}
                </Typography>

                <Typography variant="subtitle1" align="center">
                  {data && data.collection.name}
                </Typography>

                {status && changeStatusButton(status)}
              </Stack>

              <Toast
                isOpen={!!submitData}
                dismiss={() => setSubmitData(null)}
                message="Status has been updated!"
              />
              <Toast
                isOpen={!!copied}
                duration={1000}
                dismiss={() => setCopied(null)}
                message="ID copied to clipboard!"
              />

            </Paper>
          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default ProductPreview;
