import React from 'react';
import { Box, Container, Paper } from '@mui/material';
import Footer from '../Footer';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(() => ({
  bonusContainer: {
    position: 'relative',
    display:'flex',
    justifyContent: 'right',
    padding:'8px 4px'
    // right:'-10',
    // bootom:'5',
    // background: 'green',
  },
    badge: {
      position: "absolute",
      fontSize:'11px',
      color:'rgba(149, 149, 149, 0.9)',
      paddingTop: '20px',
      marginRight:'-10px'
    },
  }));
  
 const BottomBadge = (props) => {
    
    const classes = useStyles();

    return (
      <>
        <Container className = {classes.bonusContainer} >
            {props.children}
            {props.show && (<span className={classes.badge}>{props.content}</span>)}
        </Container>
        </>
    );
}

export default BottomBadge
