import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Stack, TextField, Button } from '@mui/material';
import { LoadingContext } from '../contexts/LoadingContext';
import { ErrorContext, MillaButton } from 'stockpoint-common';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import { auth } from '../api/fire';

const SignInEmailForm = ({ signUpMode, setData }) => {
  const { setIsLoading } = useContext(LoadingContext);
  const { setError } = useContext(ErrorContext);

  const { REACT_APP_TEST_USER_EMAIL, REACT_APP_TEST_USER_PASSWORD } = process.env;

  const [email, setEmail] = useState(REACT_APP_TEST_USER_EMAIL || '');
  const [password, setPassword] = useState(REACT_APP_TEST_USER_PASSWORD || '');

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setError(null);
    setData(null);
    try {
      let user;
      if (signUpMode) {
        user = await createUserWithEmailAndPassword(auth, email, password);
        await sendEmailVerification(auth.currentUser);
      } else {
        user = await signInWithEmailAndPassword(auth, email, password);
      }
      setData(user);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const isSubmitEnabled = email.length > 0 && password.length > 0;

  const titles = {
    main: signUpMode
      ? 'Please, sign-up to continue.'
      : 'Please, sign-in to continue.',
    loading: signUpMode ? 'Signing-up...' : 'Signing-in...',
    submit: signUpMode ? 'Sign-up' : 'Sign-in',
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Stack
          spacing={2}
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '90px' }}
        >
          <TextField
            required
            label="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            variant="outlined"
          />
          <TextField
            required
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            variant="outlined"
          />
          <MillaButton type="submit" disabled={!isSubmitEnabled}>
            { titles.submit }
          </MillaButton>
        </Stack>
      </form>
      { !signUpMode && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link to="/signup">
          <Button
            variant="text"
            style={{ textTransform: 'none', margin: '5px 0' }}
          >
            or Sign-up
          </Button>
        </Link>
      )}
    </>
  );
};

SignInEmailForm.propTypes = {
  signUpMode: PropTypes.bool,
  setData: PropTypes.func.isRequired,
};

SignInEmailForm.defaultProps = {
  signUpMode: false,
};

export default SignInEmailForm;
