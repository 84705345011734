import React, { useState, useContext } from 'react';
import {
  AppBar, Toolbar, Stack, Box, Button, Dialog, IconButton, Slide, Grow, Tooltip
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { makeStyles } from '@mui/styles';
import { AuthContext } from 'stockpoint-common';
import { BonusContext } from '../contexts/BonusContext';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import * as path from './routing/path'
import VectorIcon from './icons/VectorIcon';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const NavigationBar = () => {
  const { currentUser } = useContext(AuthContext);
  const { bonus, setBonusNeedsReload, bonusAvailable } = useContext(BonusContext);
  const history = useHistory();

  const title = 'Bonus Program'.toUpperCase();

  const openBonusDialog = () => {
    if (history.location.pathname != path.BONUS)
      history.push(path.BONUS);
  };

  const openBonusProgramInfo = () => {
    history.push(path.BONUS_PROGRAM);
  };

  const showBackButton = () => {
    return history.location.pathname === path.BONUS_PROGRAM || history.location.pathname === path.BONUS_EXCHANGE
  };

  const useStyles = makeStyles(() => ({
    navButton: {
      color: 'inherit',
      fontWeight: '800',
      fontSize: '18px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    starButton: {
      color: 'inherit',
      fontWeight: '600',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    buttonHover: {
      '&:hover': {
        backgroundColor: '#4a4a4a75',
      },
    },
  }));

  useEffect(() => {
    if (currentUser) {
      setBonusNeedsReload(true);
    }
  }, [currentUser])

  const classes = useStyles();
  return (
    <>
      {currentUser && (
        <AppBar position="fixed">
          <Toolbar variant="dense">
            {!showBackButton() && (
              <Grow in={!showBackButton()}>
                <Tooltip title="Show more info about bonus program" placement="bottom" arrow>
                  <Button
                    className={`${classes.navButton + ' ' + classes.buttonHover}`}
                    onClick={openBonusProgramInfo}
                  >
                    {title}
                  </Button>
                </Tooltip>
              </Grow>
            )}
            {showBackButton() && (
              <Grow in={showBackButton()} >
                <Button
                  className={`${classes.navButton + ' ' + classes.buttonHover}`}
                  onClick={() => { history.goBack() }}
                >
                  <ArrowBackIcon></ArrowBackIcon>
                  BACK
                </Button>
              </Grow>)
            }
            <Box sx={{ flexGrow: '1' }} />
            <Tooltip title="Exchange your stars" placement="bottom" arrow>
              <Stack direction="row" spacing={1} alignItems="center" className={`${classes.navButton + ' ' + classes.buttonHover}`}>
                <IconButton onClick={openBonusDialog}
                  style={{
                    padding: "9.75px 8px"
                  }}>
                  <StarIcon className={classes.starButton}
                    viewBox="3 3 21 21"
                    style={{
                      color: 'white',
                    }} />
                  <span
                    style={{
                      color: 'white',
                      fontSize: '19px',
                      fontWeight: '600',
                      padding: '0px 14px 0px 2px'
                    }}>
                    {bonusAvailable > 0 ? bonusAvailable : bonus ?? '-'}
                  </span>
                  <VectorIcon style={{
                    color: 'white',
                    fontSize: "2rem"
                  }} ></VectorIcon>
                </IconButton>
              </Stack>
            </Tooltip>
          </Toolbar>
        </AppBar>
      )
      }
    </>

  );
};

export default NavigationBar;
