import axios from 'axios';
import { getIdToken } from 'firebase/auth';
import { isDevelopment } from 'stockpoint-common';
import { auth } from './fire';

const { REACT_APP_API_ENDPOINT } = process.env;

const client = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
});

// Add header with JWT

client.interceptors.request.use(async (config) => {
  const token = await getIdToken(auth.currentUser);
  // eslint-disable-next-line no-param-reassign
  config.headers['x-access-token'] = token;
  return config;
});

export default client;

// Debug

function sleep(ms = 1000) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

client.interceptors.response.use(async (response) => {
  if (isDevelopment) {
    await sleep();
  }
  return response;
});
