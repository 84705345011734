import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { LoadingContext } from '../contexts/LoadingContext';
import { ErrorContext, MillaLogo } from 'stockpoint-common';
import { auth } from '../api/fire';
import * as path from './routing/path'


const SignOut = () => {
  const { setIsLoading } = useContext(LoadingContext);
  const { setError } = useContext(ErrorContext);

  const history = useHistory();

  const handleSingOut = () => {
    setIsLoading(true);
    setError(null);

    signOut(auth).then(() => {
      history.push(path.BASE);
    })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleSingOut();
  }, []);

  return (
    <>
      <MillaLogo />
    </>
  );
};

export default SignOut;
