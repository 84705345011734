/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete as MuiAutocomplete,
  TextField,
  CircularProgress,
} from '@mui/material';

const Autocomplete = ({
  value, setValue, options, label,
  optionToString, optionKey, optionChanged,
  loading,
  ...rest
}) => {
  const onOptionChange = (option) => {
    if (typeof option === 'string') {
      setValue(option);
    } else if (option?.inputValue) {
      // Create a new value from the user input
      setValue(option.inputValue);
    } else {
      setValue(optionToString(option));
      optionChanged(option);
    }
  };

  const getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.title;
    }
    // Regular option
    return optionToString(option);
  };

  return (
    <MuiAutocomplete
      value={value}
      onChange={(event, newValue) => onOptionChange(newValue)}
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option) => (
        <li {...props} key={optionKey(option)}>{getOptionLabel(option)}</li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
              </>
            ),
          }}
        />
      )}
      fullWidth
      {...rest}
    />
  );
};

Autocomplete.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired,
  optionToString: PropTypes.func,
  optionKey: PropTypes.func,
  optionChanged: PropTypes.func,
  loading: PropTypes.bool,
};

Autocomplete.defaultProps = {
  value: '',
  optionToString: (opt) => opt,
  optionKey: (opt) => opt,
  optionChanged: () => {},
  loading: false,
};

export default Autocomplete;
