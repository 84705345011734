import React from "react";
import { SvgIcon } from "@mui/material";


const ShoppingBagIcon = () => {

    return (
        <SvgIcon viewBox="0 0 32 32">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.9998 2.33333C11.4225 2.33333 9.33316 4.42267 9.33316 7H7.75621C5.99108 7 4.50218 8.31437 4.28324 10.0659L2.82491 21.7325C2.56378 23.8215 4.19263 25.6667 6.29788 25.6667H21.7017C23.807 25.6667 25.4358 23.8215 25.1747 21.7325L23.7164 10.0659C23.4974 8.31437 22.0085 7 20.2434 7H18.6665C18.6665 4.42267 16.5772 2.33333 13.9998 2.33333ZM16.3332 9.33333V10.5C16.3332 11.1443 16.8555 11.6667 17.4998 11.6667C18.1442 11.6667 18.6665 11.1443 18.6665 10.5V9.33333H20.2434C20.8318 9.33333 21.3281 9.77146 21.4011 10.3553L22.8594 22.022C22.9464 22.7183 22.4035 23.3333 21.7017 23.3333H6.29788C5.59613 23.3333 5.05318 22.7183 5.14022 22.022L6.59855 10.3553C6.67153 9.77145 7.16783 9.33333 7.75621 9.33333H9.33316V10.5C9.33316 11.1443 9.85549 11.6667 10.4998 11.6667C11.1442 11.6667 11.6665 11.1443 11.6665 10.5V9.33333H16.3332ZM16.3332 7C16.3332 5.71133 15.2885 4.66666 13.9998 4.66666C12.7112 4.66666 11.6665 5.71133 11.6665 7H16.3332Z" fill="#4C515C"/>
        </SvgIcon>
    );
};
export default ShoppingBagIcon;