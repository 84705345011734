import React from 'react';
import { useFormik } from 'formik';
import { Button, Container, Stack, TextField } from '@mui/material';
import * as yup from 'yup';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as path from '../routing/path';
import { useContext } from 'react';
import { BonusContext } from '../../contexts/BonusContext';
import client from '../../api';
import { ErrorContext, Toast } from 'stockpoint-common';
import { useRef } from 'react';
import { LoadingContext } from '../../contexts/LoadingContext';
import { useState } from 'react';





const validationSchema = yup.object({
    firstName: yup
        .string('Enter your  first name')
        .required('Fist name is required'),
    lastName: yup
        .string('Enter your last name')
        .required('Last name is required'),
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    address: yup
        .string('Enter your address')
        .required('Address is required'),
    zipCode: yup
        .string()
        .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Must be only digits with dash")
        .min(5, "Must be exactly 5 digits")
        .required('Zip code is required'),
    // .max(9, "Must be exactly 5 digits")
    comment: yup
        .string('Enter your comment if you want')
        .max(200, 'Too many characters. The limit is 200'),
});


export const BonusExchangeUserInfo = (props) => {

    const [submitData, setSubmitData] = useState(null);
    const { setIsLoading } = useContext(LoadingContext);
    const { setError } = useContext(ErrorContext);
    const { setBonusAvailable, setBonusNeedsReload } = useContext(BonusContext);


    const history = useHistory();
    const location = useLocation();

    const selectedRewards = useRef();


    useEffect(() => {
        if (location.state === undefined) {
            history.replace(path.BONUS);
        } else {
            selectedRewards.current = location.state.selectedRewards;
            setBonusAvailable(location.state.availableStars)
        }
    }, [])


    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            address: '',
            zipCode: '',
            comment: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await handleSubmit(values);
        },
    })


    const handleSubmit = async (formValues) => {
        try {
            setIsLoading(true);
            const resp = await client.post('exchange-rewards', {
                selectedRewards: selectedRewards.current,
                userInfo: formValues
            });
            setBonusNeedsReload(true);
            setSubmitData(resp)
            setTimeout(() => {
                history.push(path.BONUS);
            }, 2000);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Container maxWidth="sm"
                sx={{ textAlign: "center" }}>
                <h1>Contacts form</h1>
                <h4>Please, enter your contact info </h4>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <TextField
                            id="firstName"
                            name="firstName"
                            placeholder="Your name"
                            label='First Name'
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                        />
                        <TextField
                            id="lastName"
                            name="lastName"
                            placeholder="Your last name"
                            label='Last Name'
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                        />
                        <TextField
                            id="email"
                            name="email"
                            placeholder="jane@acme.com"
                            type="email"
                            label="Email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <TextField
                            id="address"
                            name="address"
                            placeholder="Your address"
                            type="text"
                            label="Address"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                        />
                        <TextField
                            id="zipCode"
                            name="zipCode"
                            placeholder="Example - 79052"
                            type="text"
                            label="Zip Code"
                            value={formik.values.zipCode}
                            onChange={formik.handleChange}
                            error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                            helperText={formik.touched.zipCode && formik.errors.zipCode}
                        />
                        <TextField
                            id="comment"
                            name="comment"
                            placeholder="Enter your comment"
                            type="text"
                            label="Comment"
                            value={formik.values.comment}
                            onChange={formik.handleChange}
                            error={formik.touched.comment && Boolean(formik.errors.comment)}
                            helperText={formik.touched.comment && formik.errors.comment}
                            multiline
                            minRows={2}
                            maxRows={6}
                        />
                        <Button variant="contained" type="submit">Submit</Button>
                    </Stack>

                </form>
            </Container>
            <Toast
                isOpen={!!submitData}
                dismiss={() => setSubmitData(null)}
                message="Exchange successful!"></Toast>
        </>
    )
};

