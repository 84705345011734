import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, SvgIcon } from '@mui/material';
import { ReactComponent as LoadingIcon } from '../assets/logo-loading.svg'

const LoadingAlert = ({ isOpen, title }) => (
  <>
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 50 }
      }}
    >
      <DialogContent style={{
        display: "flex",
        allignItems: "center",
        justifyContent: "center",
        padding: "15px"
      }}>
        <SvgIcon component={LoadingIcon} viewBox="0 0 120 112" style={{ height: '70px', width: "70px" }}>
        </SvgIcon>
        {/* <CircularProgress /> */}
      </DialogContent>
    </Dialog>
  </>
);

LoadingAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

LoadingAlert.defaultProps = {
  title: 'Loading...',
};

export default LoadingAlert;
