import React, { useState, useEffect, useContext, useRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CheckedTableRow } from "./CheckedTableRow"
import * as path from '../routing/path'

import { LoadingContext } from '../../contexts/LoadingContext';
import {
  ErrorContext,
  MillaButton,
} from 'stockpoint-common';
import client from '../../api';
import { BonusContext } from '../../contexts/BonusContext';
import { Container } from '@mui/system';
import ConfirmationDialog from '../customs/ConfirmationDialog';
import RewardsSelected from './RewardsSelected';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const Bonus = () => {
  //юзаєм значення бонусів в профілю
  const { bonus, setBonusAvailable } = useContext(BonusContext);

  const { setIsLoading } = useContext(LoadingContext);
  const { setError } = useContext(ErrorContext);

  const [rewards, setRewards] = useState(null);
  const [categories, setCategories] = useState(null);
  const [currentStarsUsed, setCurrentStarsUsed] = useState(0);
  const [availableStars, setAvailableStars] = useState(0);

  const [openConfirm, setOpenConfirm] = useState(false);
  const history = useHistory();


  useEffect(async () => {
    try {
      setIsLoading(true);
      const reqRewards = client.get('/rewards');
      const reqCategories = client.get('/reward-categories');

      const [respCategories,respRewards] = await axios.all([reqCategories,reqRewards])
      
      setCategories(respCategories.data.data);
      let rewards = respRewards.data.data.map((rw) => {
        return { ...rw, checked: false }
      });
      setRewards(rewards);

    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    let availableS = bonus - currentStarsUsed;
    setAvailableStars(availableS);
    setBonusAvailable(availableS);
  }, [bonus, currentStarsUsed])


  function setRewardChecked(id, checked) {
    let newRewards = rewards.map(rw => {
      if (rw.id === id) {
        //if checked changed
        if (rw.checked !== checked) {
          let starDiff = checked ? rw.star_value : -1 * rw.star_value
          setCurrentStarsUsed(currentStarsUsed + starDiff)
        }

        return { ...rw, checked: checked };
      }
      return rw;
    })
    setRewards(newRewards);
  }

  function getSortedRewardsForCategory(rewards, category_id) {
    return rewards.filter(el => el.category_id == category_id).sort(compareRewards);
  }

  function compareRewards(rf, rs) {
    if (rf.star_value > rs.star_value) return -1;
    if (rf.star_value < rs.star_value) return 1;
    return 0;
  }

  const handleResultFromDialog = async (result) => {
    if (result) {
      let selectedRewards = rewards.filter((r) => r.checked);
        history.push(path.BONUS_EXCHANGE, {
        selectedRewards: selectedRewards,
        availableStars: availableStars
      });
    }
  }


  return (
    <>
      {rewards && (
        <Stack
          spacing={2}
          justifyContent="center"
          alignItems="center"
          style={{ margin: '4px', position: "relative" }}
        >
          <Typography variant="subtitle1" align="center" fontWeight={'800'} fontSize={'18px'}>
            Let&apos;s exchange your stars
          </Typography>
          <Container style={{ padding: '4px 0px 70px 0px' }}>
            {categories.map((category, id) => (

              <Accordion key={id}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{category.name}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '8px 8px 16px 8px' }} >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 600 }}>Item</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 600 }} style={{padding:"8px 0px"}}>Stars</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getSortedRewardsForCategory(rewards, category.id).map((reward, idx) => (
                        <CheckedTableRow key={idx}
                          reward={reward}
                          idx={idx}
                          onRewardChange={setRewardChecked}
                          availableStars={availableStars}>
                        </CheckedTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            ))}
          </Container>
          <div style={{
            position: "fixed",
            bottom: "0",
            textAlign: "center",
            backgroundColor: "white",
            width: '100%'
          }}>
            <MillaButton
              disabled={rewards.find(r => r.checked) === undefined}
              style={{ marginBottom: "20px" }}
              onClick={() => setOpenConfirm(true)}>
              Exchange
            </MillaButton>
          </div>
        </Stack>
      )}
      <ConfirmationDialog
        open={openConfirm}
        title={" Confirm your choise"}
        setOpen={(opn) => setOpenConfirm(opn)}
        onSuccessResult={(res) => handleResultFromDialog(res)}>
        <RewardsSelected rewards={rewards}></RewardsSelected>
      </ConfirmationDialog>
    </>
  );
};

export default Bonus;
