/* eslint-disable react/prop-types */
import React from 'react';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import { CssBaseline, Box, Paper } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  AuthProvider, ErrorProvider, PrivateRoute,
  defaultTheme, isDevelopment, AppVersion,
} from 'stockpoint-common';
import { BonusProvider } from '../contexts/BonusContext';


import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../api/fire';

import NavigationBar from './NavigationBar';
import { Routing } from './routing/Routing';
import { LoadingProvider } from '../contexts/LoadingContext';



const useStyles = makeStyles(() => ({
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: "48px 0px 0px 0px",
    height: '100vh',
    // background: 'yellow',
  }
}));

const App = () => {
  const classes = useStyles();
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <Router>
              <AuthProvider onAuthChange={(cb) => onAuthStateChanged(auth, cb)}>
                <Box className={classes.topContainer}>
                  <ErrorProvider>
                  <LoadingProvider>
                    <BonusProvider>
                      <NavigationBar />
                      <Routing>
                      </Routing>
                      {/* {isDevelopment && <AppVersion />} */}
                    </BonusProvider>
                  </LoadingProvider>
                  </ErrorProvider>
                </Box>
              </AuthProvider>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;
