import React, { useContext } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import BottomBadge from '../customs/BottomBadge';
import { useEffect } from 'react';
import { useState } from 'react';
import { BonusContext } from '../../contexts/BonusContext';
import { Container, Stack } from '@mui/material';


export const CheckedTableRow = ({ idx, reward, availableStars, onRewardChange }) => {
    const { bonus } = useContext(BonusContext);
    const [checked, setChecked] = useState(false);
    const [isDisabledForCheck, setIsDisabledForCheck] = useState(false);
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        setChecked(reward.checked === undefined ? false : reward.checked)
    }, [])

    useEffect(() => {
        setIsDisabledForCheck(reward.star_value > availableStars && !checked);
    }, [availableStars, reward.checked, clicked, checked])


    function handleOnClick() {
        let isChecked = checked;
        if (reward.star_value <= availableStars || checked) {
            isChecked = !isChecked;
            setChecked(isChecked);
        }
        setClicked(true);
        onRewardChange(reward.id, isChecked);
    }


    function getBadgeContent() {
        return `+${(reward.star_value - availableStars)}`
    }



    return (<TableRow onClick={() => handleOnClick()}
        // eslint-disable-next-line react/no-array-index-key
        key={idx}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        style={{ cursor: "pointer", backgroundColor: checked ? "rgba(222, 222, 222, 0.52)" : "transparent" }}>
        <TableCell scope="row" style={{ padding: "8px 0px" }}>
            <Stack direction="row" style={{ display: "flex", alignItems: "center" }}>
                <Container style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "85px",
                    width: "85px",
                    padding: "0px",
                    margin: "0px 8px 0px 0px"
                }}>
                    <img
                        src={reward.image_url}
                        loading="lazy"
                        style={{ objectFit: "contain", borderRadius: "5px", height: "auto", maxHeight: "85px", maxWidth: "85px", width: "auto" }}
                    />
                </Container>
                {reward.name}
            </Stack>
        </TableCell>
        <TableCell align="right" style={{ padding: "8px 0px" }} size='small'>
            <BottomBadge content={getBadgeContent()} show={isDisabledForCheck && bonus > 0}>
                {reward.star_value}
            </BottomBadge>
        </TableCell>
        <TableCell align="right" size='small'><Checkbox checked={checked} disabled={isDisabledForCheck}></Checkbox></TableCell>
    </TableRow>)
}
