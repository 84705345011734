/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import client from '../api';
import * as path from '../components/routing/path'


export const BonusContext = React.createContext();

export const BonusProvider = ({ children }) => {
  const [bonus, setBonus] = useState(undefined);
  const [bonusNeedsReload, setBonusNeedsReload] = useState(false);
  const [bonusAvailable, setBonusAvailable] = useState(0);
  //const [prevLocation, setPrevLocation] = useState();
  const location = useLocation();


  const fetchBonusInfo = async () => {
    try {
      const resp = await client.get('/bonus');
      setBonus(resp.data.data.bonus);
    } catch (err) {
      setBonus(0);
    } finally {
      // Marks update as finished
      setBonusNeedsReload(false);
    }
  };

  useEffect(async () => {
    if (bonusNeedsReload) {
      await fetchBonusInfo();
    }
  }, [bonusNeedsReload]);

  useEffect(() => {
    if(!location.pathname.includes(path.BONUS)){
      setBonusAvailable(0);
    }
  }, [location])


  return (
    <BonusContext.Provider value={{ bonus, bonusAvailable, setBonusNeedsReload, setBonusAvailable }}>
      {children}
    </BonusContext.Provider>
  );
};
