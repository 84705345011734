import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'

const RewardsSelected = (props) => {

    const [rewards, setRewards] = useState(null);

    useEffect(() => {
        if (props.rewards != undefined) {
            setRewards(props.rewards.filter(r => r.checked));
        }
    }, [props.rewards])

    return (<>
        {rewards && (<>
            <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{fontWeight:"600"}}>Name</TableCell>
                            <TableCell style={{fontWeight:"600"}}>Stars</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rewards.map((r, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {r.name}
                                </TableCell>
                                <TableCell >{r.star_value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Stack direction={"row"}
                spacing={1}
                justifyContent="flex-end"
                alignItems="center"
                style={{
                    padding:"6px",
                    color:"rgba(46, 47, 23, 0.8)"
                    }}>
                <Typography>Total:</Typography>
                <Typography>{rewards.map(({ star_value }) => star_value).reduce((sum, i) => sum + i, 0)}</Typography>
            </Stack>

        </>
        )}
    </>
    )
}
export default RewardsSelected
