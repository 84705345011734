import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { LoadingContext } from '../contexts/LoadingContext';
import {
  ErrorContext,
  MillaLogo, MillaButton,
} from 'stockpoint-common';
import client from '../api';
import { getPreviewId, setPreviewId } from '../utils';
import Autocomplete from '../controls/Autocomplete';
import { PaperContainer } from './customs/PaperContainer';

const Profile = () => {
  const { setIsLoading } = useContext(LoadingContext);
  const { setError } = useContext(ErrorContext);

  const history = useHistory();

  const [initialLoad, setInitialLoad] = useState(true);

  const [loadingCountries, setLoadingCountries] = useState(false);
  const [countries, setCountries] = useState([]);

  const [loadingStockpoints, setLoadingStockpoints] = useState(false);
  const [cities, setCities] = useState([]);
  const [allStockpoints, setAllStockpoints] = useState([]);
  const [stockpoints, setStockpoints] = useState([]);

  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [stockpoint, setStockpoint] = useState('');
  const [stockpointId, setStockpointId] = useState(null);

  const [data, setData] = useState(null);
  const [submitData, setSubmitData] = useState(null);

  useEffect(async () => {
    try {
      setIsLoading(true);
      const resp = await client.get('/profile');
      setData(resp.data.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
      setInitialLoad(false);
    }
  }, []);

  useEffect(() => {
    if (data && data.stockpoint) {
      setCountry(data.stockpoint.country || '');
      setCity(data.stockpoint.city || '');
      setStockpoint(data.stockpoint.name || '');
      setStockpointId(data.stockpoint.id);
    }
  }, [data]);

  useEffect(async () => {
    try {
      setLoadingCountries(true);
      const resp = await client.get('/countries');
      setCountries(resp.data.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoadingCountries(false);
    }
  }, []);

  useEffect(async () => {
    if (country && country.length > 0) {
      try {
        setLoadingStockpoints(true);
        const resp = await client.get(`/stockpoints-all?country=${country}`);
        const allCities = resp.data.data.map((obj) => obj.city);
        setCities([...new Set(allCities)].sort());
        setAllStockpoints(resp.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoadingStockpoints(false);
      }
    }
  }, [country]);

  useEffect(() => {
    if (city && city.length > 0) {
      const items = allStockpoints
        .filter((obj) => obj.city === city)
        .sort((obj) => obj.name);
      setStockpoints(items);
    }
  }, [city, allStockpoints]);

  useEffect(async () => {
    // Clear when country changes
    if (country && !initialLoad) {
      setCity('');
      setStockpoint('');
    }
  }, [country]);

  useEffect(async () => {
    // Clear when city changes
    if (city && !initialLoad) {
      setStockpoint('');
    }
  }, [city]);

  useEffect(() => {
    const proceed = () => {
      const previewId = getPreviewId();
      if (previewId) {
        history.push(`/preview/${previewId}`);
        // Clear PreviewId
        setPreviewId(null);
      }
    };
    if (submitData) {
      proceed();
    }
  }, [submitData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setError(null);
    setSubmitData(null);
    try {
      const resp = await client.post('/profile', { stockpoint_id: stockpointId });
      setSubmitData(resp.data.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const isFormComplete = () => (
    country && country.length > 0
    && city && city.length > 0
    && stockpoint && stockpoint.length > 0
  );

  return (
    <>
      <PaperContainer elevation={1}>
        <MillaLogo />
        <Typography
          variant="subtitle1"
          style={{ margin: '30px 0' }}
        >
          Let&apos;s choose your location
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Stack
            spacing={2}
            justifyContent="center"
            alignItems="center"
            style={{ margin: '5px 0' }}
          >
            <Autocomplete
              label="Country"
              value={country}
              setValue={setCountry}
              options={countries}
              loading={loadingCountries}
              disabled={loadingCountries}
            />
            <Autocomplete
              label="City"
              value={city}
              setValue={setCity}
              options={cities}
              loading={loadingStockpoints}
              disabled={loadingStockpoints}
            />
            <Autocomplete
              label="Stock Point"
              value={stockpoint}
              setValue={setStockpoint}
              options={stockpoints}
              optionToString={(opt) => opt.name}
              optionKey={(opt) => opt.id}
              optionChanged={(opt) => setStockpointId(opt.id)}
              loading={loadingStockpoints}
              disabled={loadingStockpoints}
            />
            <MillaButton type="submit" disabled={!isFormComplete()}>Submit</MillaButton>
          </Stack>
        </form>
      </PaperContainer>
    </>
  );
};

export default Profile;
