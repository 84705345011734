/* eslint-disable react/prop-types */
import React, { useState, createContext, useEffect } from 'react';
import LoadingAlert from '../loading/LoadingAlert';

export const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      <LoadingAlert isOpen={isLoading} />
      {children}
    </LoadingContext.Provider>
  );
};
