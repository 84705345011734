import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Stack, Divider } from '@mui/material';

import {
  ErrorContext,
  DialogOption, confirm,
  MillaLogo, FacebookButton, GoogleButton, EmailButton, AuthContext, AppleButton
} from 'stockpoint-common';
import { signInWithPopup } from 'firebase/auth';
import {
  auth, googleProvider, facebookProvider, appleProvider,
  handleAccountAlreadyExistsError,
} from '../api/fire';
import SignInEmailForm from './SignInEmailForm';
import client from '../api';
import { getPreviewId, setPreviewId } from '../utils';
import { PaperContainer } from '../components/customs/PaperContainer'
import * as path from './routing/path'
import { LoadingContext } from '../contexts/LoadingContext';


const EMAIL_SIGNIN_ENABLED = false;
const FACEBOOK_SIGNIN_ENABLED = false;

const SignIn = ({ signUpMode = false }) => {
  const { setIsLoading } = useContext(LoadingContext);
  const { setError } = useContext(ErrorContext);

  const history = useHistory();

  const [data, setData] = useState(null);

  const [signInWithEmail, setSignInWithEmail] = useState(false);
  const { currentUser } = useContext(AuthContext);


  const handleProviderSignIn = async (provider) => {
    setIsLoading(true);
    setError(null);
    setData(null);
    try {
      const result = await signInWithPopup(auth, provider);
      const { user } = result;
      setIsLoading(false);
      setData(user);
    } catch (err) {
      if (err.code !== 'auth/account-exists-with-different-credential') {
        setIsLoading(false);
        setError(err.message);
        return;
      }
      // Handle Firebase accounts linking
      const user = await handleAccountAlreadyExistsError(err, async () => {
        // Hide loading indicator while presenting ConfirmDialog
        setIsLoading(false);
        const result = await confirm({
          title: 'Account already exists',
          content: "You've already signed-in using different provider, accounts will be merged.",
          options: [DialogOption.ok],
        });
        setIsLoading(false);
        return result === 'ok';
      });
      setData(user);
    }
  };

  const isComplete = (profileData) => {
    const { stockpoint } = profileData;
    return (stockpoint
      && stockpoint.country
      && stockpoint.city
      && stockpoint.name);
  };

  const proceedAfterLogin = async () => {
    setIsLoading(true);
    try {
      const resp = await client.get('/profile');
      if (isComplete(resp.data.data)) {
        const previewId = getPreviewId();
        if (previewId) {
          // Clear PreviewId
          setPreviewId(null);
          history.replace(path.PREVIEW + `/${previewId}`);
        } else {
          history.replace(path.BONUS)
        }
      } else {
        history.push(path.PROFILE);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      history.replace(path.BONUS)
    }
  }, [])

  useEffect(() => {
    if (data) {
      proceedAfterLogin();
    }
  }, [data]);

  return (
    <>
      <PaperContainer elevation={1}>
        <MillaLogo />
        {!signInWithEmail && (
          <Stack
            spacing={2}
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '90px' }}
          >
            {FACEBOOK_SIGNIN_ENABLED && (<FacebookButton onClick={() => handleProviderSignIn(facebookProvider)} />)}
            <AppleButton onClick={() => handleProviderSignIn(appleProvider)} />
            <GoogleButton onClick={() => handleProviderSignIn(googleProvider)} />
            {EMAIL_SIGNIN_ENABLED && (
              <>
                <Divider flexItem />
                <EmailButton onClick={() => setSignInWithEmail(!signInWithEmail)} />
              </>
            )}
          </Stack>
        )}
        {signInWithEmail && <SignInEmailForm signUpMode={signUpMode} setData={setData} />}
      </PaperContainer>
    </>
  );
};

SignIn.propTypes = {
  signUpMode: PropTypes.bool,
};

SignIn.defaultProps = {
  signUpMode: false,
};

export default SignIn;
