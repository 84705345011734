/* eslint-disable import/prefer-default-export */

// PreviewId

const PREVIEW_ID_KEY = 'preview';

const getPreviewId = () => sessionStorage.getItem(PREVIEW_ID_KEY);

const setPreviewId = (newValue) => {
  if (newValue) {
    sessionStorage.setItem(PREVIEW_ID_KEY, newValue);
  } else {
    sessionStorage.removeItem(PREVIEW_ID_KEY);
  }
};

export { getPreviewId, setPreviewId };
