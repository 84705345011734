import React, { useContext, useState } from 'react';
import { Box, Button, SvgIcon, Typography } from '@mui/material';
import { AuthContext, MillaLogo } from 'stockpoint-common';
import { PaperContainer } from '../components/customs/PaperContainer'
import LoginIcon from '@mui/icons-material/Login';
import { Link } from 'react-router-dom';
import * as path from './routing/path'
import { ReactComponent as LogoSvg } from '../assets/logo-welcome.svg'
import { useEffect } from 'react';



const Welcome = () => {
  const { currentUser } = useContext(AuthContext);
  return (
    <>
      <PaperContainer elevation={1}>
        <SvgIcon component={LogoSvg} inheritViewBox style={{ height: '120px', width: "120px" }}>
        </SvgIcon>
        <Typography
          variant="subtitle1"
          style={{ margin: '30px 0' }}
        >
          Scan QR code to continue
        </Typography>
      </PaperContainer>

      {!currentUser && (<Box
        sx={{
          display: 'flex',
          alighItems: 'center',
          justifyContent: 'center',
          margin: '10px',
        }}
      >
        <Link to={path.SIGN_IN} style={{ textDecoration: 'none' }}>
          <Button
            sx={{ textTransform: 'none' }}
            variant="text"
          ><LoginIcon /> Sign in
          </Button>
        </Link>
      </Box>)}
    </>
  )
};

export default Welcome;
