import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Icon, SvgIcon } from '@mui/material';
import { AuthContext } from 'stockpoint-common';
import * as path from './routing/path'
import LogoutIcon from '@mui/icons-material/Logout';


const Footer = () => {
  const { currentUser } = useContext(AuthContext);
  return (
    <>
      {currentUser && (
        <Box
          sx={{
            display: 'flex',
            alighItems: 'center',
            justifyContent: 'center',
            margin: '10px',
          }}
          
        >
          <Link to={path.SIGN_OUT} style={{textDecoration:'none'}}>
            <Button
              sx={{ textTransform: 'none' }}
              variant="text"
            >
              <LogoutIcon /> Sign out
            </Button>
          </Link>
        </Box>
      )}
    </>
  );
};

export default Footer;
