import { Button, Container, FormControl, Grid, InputLabel, List, MenuItem, Paper, Select, Stack, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import client from '../../api';
import { LoadingContext } from '../../contexts/LoadingContext';
import { ErrorContext } from 'stockpoint-common';
import ShoppingBagIcon from '../icons/ShoppingBagIcon';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box } from '@mui/system';



const events = ["All", "InStore", "Sold", "Return"]
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 300,
            width: 250,
        }
    }
};


export default function ProductsScanned() {

    const [products, setProducts] = useState(undefined);
    const [productsFiltered, setProductsFiltered] = useState(undefined);

    const [selectedEvent, setSelectedEvent] = useState(events[0]);

    const [showLoadMore, setShowLoadMore] = useState(true);
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const { setError } = useContext(ErrorContext);
    const offsetRef = useRef(0);


    const loadScannedProducts = async () => {
        try {
            setError(null);
            setIsLoading(true);

            const params = { limit: 3, offset: offsetRef.current };
            const resp = await client.get('/products-scanned', { params });

            const items = resp.data.data;
            offsetRef.current += items.length;
            setShowLoadMore(items.length !== 0 && items.length === params.limit);
            // Append to products if exist
            if (products) {
                setProducts([...products, ...items]);
            } else {
                setProducts(items);
            }
        } catch (err) {
            setError(err.response?.data?.error || err.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(async () => {
        await loadScannedProducts();
    }, []);

    useEffect(() => {
        if (selectedEvent === "All") {
            setProductsFiltered(products);
        } else {
            setProductsFiltered(products.filter((p) => p.event === selectedEvent.toLowerCase()))
        }

    }, [selectedEvent, products])

    const renderStatus = (event) => {
        switch (event) {
            case "instore": {
                return <Box
                    style={{
                        display: 'flex',
                        allignItems: 'center',
                        justifyContent: 'center',
                    }}
                    m={1}
                >
                    <Box style={{
                        display: 'flex',
                        allignItems: 'center',
                        justifyContent: 'center',
                        width: "fit-content",
                        padding: "4px",
                        backgroundColor: "#eceed54a",
                        borderRadius: "10px"
                    }}>
                        <AddBusinessIcon></AddBusinessIcon>
                        <Typography align={"center"}>{event.toUpperCase()}</Typography>
                    </Box>
                </Box>
            }
            case "sold": {
                return <Box
                    style={{
                        display: 'flex',
                        allignItems: 'center',
                        justifyContent: 'center'
                    }}
                    m={1}
                >
                    <Box style={{
                        display: 'flex',
                        allignItems: 'center',
                        justifyContent: 'center',
                        width: "fit-content",
                        padding: "4px",
                        backgroundColor: "#c1e3bd45",
                        borderRadius: "10px"
                    }}>
                        <ShoppingBagIcon></ShoppingBagIcon>
                        <Typography align={"center"}>{event.toUpperCase()}</Typography>
                    </Box>
                </Box >
            }
            case "return": {
                return <Box
                    style={{
                        display: 'flex',
                        allignItems: 'center',
                        justifyContent: 'center'
                    }}
                    m={1}
                >
                    <Box style={{
                        display: 'flex',
                        allignItems: 'center',
                        justifyContent: 'center',
                        width: "fit-content",
                        padding: "4px",
                        backgroundColor: "#f1e5e559",
                        borderRadius: "10px"
                    }}>
                        <KeyboardReturnIcon></KeyboardReturnIcon>
                        <Typography align={"center"}>{event.toUpperCase()}</Typography>
                    </Box>
                </Box>
            }
            default: {
                return <Typography align={"center"}>{event.toUpperCase()}</Typography>
            }
        }
    }



    return (
        <>
            {productsFiltered && (
                <FormControl sx={{ minWidth: 250, mb: 2 }}>
                    <InputLabel id="label1">Event</InputLabel>
                    <Select
                        value={selectedEvent}
                        labelId="label1"
                        onChange={(e) => setSelectedEvent(e.target.value)}
                        variant="standard"
                        size="small"
                        renderValue={(status) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                <FilterAltIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />{status}
                            </Box>
                        )}
                        MenuProps={MenuProps}>
                        {events.map((s, i) => (
                            <MenuItem key={i} value={s}>{s}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            <List sx={{
                width: '100%',
                position: 'relative',
                overflow: 'auto',
                maxHeight: '600px',
                '& ul': { padding: 0 },
            }}>
                {productsFiltered && productsFiltered.map((p, i) => {
                    return (
                        <Paper elevation={4} key={i} sx={{ m: 2 }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography style={{ fontWeight: "600" }} align={"center"} m={1}>{p.product.model}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography align={"center"} variant={"caption"} m={1}>{p.product.collection_name}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography align={"left"} variant={"caption"} m={1}>{p.product.color},{p.product.size}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {renderStatus(p.event)}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{ fontWeight: "600" }} align={"left"} m={1}>{new Date(p.scan_date).toLocaleString(window.navigator.language)}</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    )
                })}

            </List>
            {!isLoading && showLoadMore && (
                <Container style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
                    <Button
                        variant="outlined"
                        onClick={async () => loadScannedProducts()}
                    >
                        Load more
                    </Button>
                </Container>
            )}
        </>
    )
}
