import React from 'react';
import { Typography } from '@mui/material';
import { MillaLogo } from 'stockpoint-common';

const NotFound = () => (
  <>
    <MillaLogo />
    <Typography variant="h5">404 Not Found</Typography>
  </>
);

export default NotFound;
