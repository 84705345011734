import React from "react";
import { SvgIcon } from "@mui/material";


const VectorIcon = () => {

    return (
        <SvgIcon viewBox="2 2 38 27">
            <path d="M31.4487 10.7811C31.4487 10.2615 31.2423 9.76325 30.8749 9.39587C30.5075 9.02849 30.0093 8.8221 29.4897 8.8221H6.785L11.2907 4.33601C11.6596 3.96712 11.8668 3.46681 11.8668 2.94513C11.8668 2.42344 11.6596 1.92313 11.2907 1.55424C10.9218 1.18536 10.4215 0.978119 9.8998 0.978119C9.37812 0.978119 8.8778 1.18536 8.50891 1.55424L0.672953 9.3902C0.401128 9.66568 0.21699 10.0155 0.143775 10.3955C0.070559 10.7756 0.111546 11.1688 0.261565 11.5255C0.408528 11.8833 0.6581 12.1895 0.978826 12.4056C1.29955 12.6218 1.67708 12.7381 2.06384 12.7401H29.4897C30.0093 12.7401 30.5075 12.5337 30.8749 12.1663C31.2423 11.7989 31.4487 11.3006 31.4487 10.7811ZM39.1279 17.8726C38.981 17.5149 38.7314 17.2086 38.4107 16.9925C38.0899 16.7764 37.7124 16.66 37.3257 16.6581H9.8998C9.38024 16.6581 8.88196 16.8645 8.51458 17.2318C8.1472 17.5992 7.94081 18.0975 7.94081 18.617C7.94081 19.1366 8.1472 19.6349 8.51458 20.0023C8.88196 20.3696 9.38024 20.576 9.8998 20.576H32.6045L28.0988 25.0621C27.9152 25.2442 27.7695 25.4609 27.67 25.6996C27.5706 25.9384 27.5194 26.1944 27.5194 26.453C27.5194 26.7116 27.5706 26.9677 27.67 27.2064C27.7695 27.4451 27.9152 27.6618 28.0988 27.8439C28.2809 28.0275 28.4976 28.1732 28.7363 28.2727C28.975 28.3722 29.2311 28.4234 29.4897 28.4234C29.7483 28.4234 30.0044 28.3722 30.2431 28.2727C30.4818 28.1732 30.6985 28.0275 30.8806 27.8439L38.7165 20.0079C38.9884 19.7325 39.1725 19.3826 39.2457 19.0026C39.3189 18.6226 39.278 18.2294 39.1279 17.8726Z" fill="#E7E9FF"/>
        </SvgIcon>
    );
};
export default VectorIcon;