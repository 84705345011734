import React from 'react';
import { Box, Paper } from '@mui/material';
import Footer from '../Footer';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(() => ({
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      // background: 'green',
    },
    paperFooterContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      margin: '40px 20px',
      // background: 'blue',
    },
    paperContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      // background: 'red',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '30px 30px 50px 30px',
      width: '100%',
      maxWidth: '500px',
      // background: 'brown',
    },
  }));
export const PaperContainer = (props) => {
    
    const classes = useStyles();

    return (
        <Box className={classes.contentContainer}>
            <Box className={classes.paperFooterContainer}>
                <Box className={classes.paperContainer}>
                    <Paper className={classes.paper}  elevation={props.elevation ?? 0}>
                        {props.children}
                    </Paper>
                </Box>
                <Footer />
            </Box>
        </Box>
    )
}
